.cursor-pointer {
    cursor: pointer;
}

.mt-20{
    margin-top: 20vh;
}

.f-size-14 {
    font-size: 14px;
}

.f-size-18 {
    font-size: 18px !important;
    color: $black;
    text-align: center;
    
    &:focus {
        color: $black;
    }
}

.f-size-22 {
    font-size: 22px;
}

.d-flex-none {
    flex-direction: unset;
}

.w-4 {
    width: 4%;
}

.w-6 {
    width: 6%;
}

.w-10 {
    width: 10%;
}

.w-15-5 {
    width: 15.5%;
}

.w-18 {
    width: 18%;
}

.w-20 {
    width: 20%;
}

.w-23 {
    width: 23%;
}

.w-23-5 {
    width: 23.5%;
}

.w-24 {
    width: 24%;
}

.w-30 {
    width: 30%;
}

.w-32 {
    width: 32%;
}

.w-45 {
    width: 45%;
}