$sidebarWidth: 190px;

.main-content {
    margin-left: 40px;
    width: 100%;
    transition: 450ms;
}

#navbar-main {
    position: fixed;
    border-bottom: 1px solid #e7e7e7;
    width: 100%;
    z-index: 2;
}

.sidebar {
    position: fixed;
    // display: flex;
    top: 70px;
    width: 40px;
    height: 100vh;
    // transition: 550ms;
    padding: 0.5rem;
    overflow-y: auto;
    z-index: 2;

    .navbar-nav{
        flex-direction: column;
        margin-left: -1.5rem;
        margin-right: -1.5rem;

        .nav-link > i {
            font-size: 1rem;
            line-height: 1.8rem;
            width: 22px;
            text-align: center;
        }

        .nav-link {
            .link-text {
                display: none;
            }
        }
    }
}

.sidebar.expanded-nav {
    .navbar-nav {
        .nav-link { 
            .link-text {
                display:inline;
            }
        }
    }
}

.sidebar-btn {
    cursor: pointer;
}

.expanded-nav {
    width: $sidebarWidth;
}

.expanded-menu {
    margin-left: $sidebarWidth;
    // transition: 550ms;
}

@media only screen and (max-width: 768px) {
    #sidenav-main {
        // max-width: 42px;
    }
    // .nav-menu {
    //     position: fixed;
    //     left: -100%;
    //     top: 5rem;
    //     flex-direction: column;
    //     background-color: #fff;
    //     width: 100%;
    //     border-radius: 10px;
    //     text-align: center;
    //     transition: 0.3s;
    //     box-shadow:
    //         0 10px 27px rgba(0, 0, 0, 0.05);
    // }

    // .nav-menu.active {
    //     left: 0;
    // }

    // .nav-item {
    //     margin: 2.5rem 0;
    // }

    // .hamburger {
    //     display: block;
    //     cursor: pointer;
    // }

}