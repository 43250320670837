@import "sidenav";

$optileaderTeal: #11CDEF;
$black: #000;
$white: #fff;
$silver: #c1c1c1;

.optileader-teal-background {
    background-color: $optileaderTeal;

    &:hover, &:active {
        background-color: #21dcfe !important;
        color: $white !important;
    }
}

.optileader-logo {
    font-family: 'Comfortaa';
    font-size: 34px;
}

.optileader-teal-color {
    color: $optileaderTeal;
}

.silver-background {
    background-color: $silver;
}

.light-silver-background {
    background-color: #f1f1f1;
}

.transparent-background {
    background-color: transparent !important;
    .breadcrumb {
        background-color: transparent; 
    }
}

.white-font-color {
    color: $white;
}

.black-font-color {
    color: $black;
}

.btn-secondary.disabled.disabled-button {
    color: $white;
    background-color: #bcbcbc;
    border-color: #bcbcbc;
}

#root {
    .login-screen {
        margin-left: 0;
        background: #172b4d;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.manufacturer-buttons {
    .btn-primary {
        padding: 10px;
    }
}

.btn-group {
    .btn-primary {
        background: #d3d3d3;
        border-radius: 0;
        box-shadow: none;
        border: 1px solid transparent !important;
    }
    .btn-primary.active {
        background-color: $optileaderTeal !important;
    }
}

#navbar-main {
    background-color: $white;
}

.no-cell-padding.no-cell-padding {

    td {
        padding: 0 !important;
    }
}

.customer-measurements-list {
    .row:last-child {
        display: none;
    }
}

.eye-direction {
    width: 25px;
}

.measurement-cell-width {
    width: 50px;
    text-align: center;
}

.contact-lenses-table {
    width:800px;

    td {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.common-condition-table {
    width: 890px;

    td {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.lenses-table {
    width: 1000px;
    
    td {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.container-wrapper {
    position: relative;
    top: 75px;
}

td.f-size-16, th.f-size-16, .f-size-16 {
    font-size: 16px !important;
    @media only screen and (max-width: 1200px) {
        font-size: 14px !important;
    }
}

.alert-teal {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.orders-table {
    th {
        padding: 1rem !important;
        @media only screen and (max-width: 1200px) {
            padding: 0.5rem !important;
        }
    }

    tr, td {
        padding: 0.75rem !important;
        @media only screen and (max-width: 1200px) {
            padding: 0.25rem !important;
        }
    }
}

.main-content .container-wrapper.container-fluid {
    @media only screen and (max-width: 1200px) {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

.table-measurements {
    background-color: $white;

    th, tr, td {
        border: 2px solid $optileaderTeal;
        padding: 0rem 0.25rem !important;
    }

    td {
        padding: 0;
        vertical-align: middle;
        
        input {
            border: 1px solid transparent;

            &:focus {
                border: 1px solid transparent;
            }
        }
    }
}

.other-observations-table {
    background-color: $white;

    tr, td {
        border: 2px solid $optileaderTeal;
        padding: 0rem 0.25rem !important;
    }

    th {
        border: 2px solid $optileaderTeal;
    }

    td {
        padding: 0;
        vertical-align: middle;
        
        input {
            border: 1px solid transparent;

            &:focus {
                border: 1px solid transparent;
            }
        }
    }
}

.check-box-30 {
    width: 30px;
    height: 30px;
    background-color: $optileaderTeal;
}

.table-border-blue.lenses-view {
    th, tr {
        border: 2px solid $optileaderTeal;
        padding: 0.25rem !important;
    }
}

.table-border-blue {

    background-color: $white;

    th, tr {
        border: 2px solid $optileaderTeal;
        padding: 0.25rem !important;
    }

    td {
        padding: 0 ;
        vertical-align: middle;
        border: 2px solid $optileaderTeal;
        
        input {
            border: 1px solid transparent;

            &:focus {
                border: 1px solid transparent;
            }
        }
    }

    .p-l-1 {
        padding-left: 1rem !important;
    }
}

.table-border-blue.transparent-background {
    background: transparent;
}

.nav-item {
    cursor: pointer;
}

.datepicker-styled {

    .DayPickerInput {
        input {
            width: 120px;
            text-align: center;
            border: 1px solid transparent;
        }
    }

    .react-datepicker-wrapper{
        .react-datepicker__input-container {
            input {
                width: 120px;
                text-align: center;
                border: 1px solid transparent;
            }
        }
    }
    
}

.pagination {
    margin-bottom: 0;
}

.auth-info-message {
    background-color: rgba(0,0,0, 0.2);
}

.facebook-link, .facebook-link:hover {
    text-decoration:none;
    color:white;
}
